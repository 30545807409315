
import React, { useState } from 'react';
import PropTypes from 'prop-types'
import axiosInstance from '../../../api/axios'
import BackgroundLetterAvatars from '../../../layouts/full-layout/customizer/LetterAvatar'
import { Box ,Avatar, Typography } from '@mui/material'



const GroupMembers = props => {
  return (
        <Box display="flex" alignItems="center">
        {props.map((team)=>(
            BackgroundLetterAvatars(team.first_name + ' ' + team.last_name,'',30,30,12,'-8px')
        ))} 
        </Box>
  )
}

GroupMembers.propTypes = {id: PropTypes.string}

export default GroupMembers