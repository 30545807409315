import { createContext, useState , useEffect } from "react";
import { getUser } from "../api/utils";

const AuthContext = createContext({});

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    useEffect(()=>{
        let _auth = getUser() 
        
        if(_auth!==null && _auth!==undefined && _auth!== ""){
            
            console.log(_auth);
            const user  = _auth['user'];
            const accessToken  = _auth['accessToken'];
            const refreshToken  =_auth['refreshToken'];
            setAuth({ user, accessToken , refreshToken })
        }
    },[]);
    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}


