import * as React from 'react';
import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
  let hash = 0;
  let i;
  let color = '#';
  /* eslint-disable no-bitwise */
  if (string!== null && string!== undefined) {
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  }
  return color;
}
function getchildren(string){
  const names = string.split(' ')
  if (names !== undefined && names!= null && names.length > 0){
    if (names.length >= 2){
      return (names[0].length > 0 ? names[0][0].toUpperCase() : '') + ' ' + (names[1].length > 0 ? names[1][0].toUpperCase() : '')
    }
    return (names[0].length > 0 ? names[0][0].toUpperCase() : '')
  }
  return '-'
}

function stringAvatar(name, width, height, fontSize, ml , image , colorbg ) {
  if(image !== ''){
    return {
      sx: {
        width: width,
        height: height,
        fontSize: fontSize,
        ml: ml,
      },
      src: `${image}`,
      alt: `${image}`
    };  
  }
  if (name!==null && name!==undefined){
  return {
    sx: {
      bgcolor: (colorbg === '' ? stringToColor(name) : colorbg),
      width: width,
      height: height,
      fontSize: fontSize,
      ml: ml,
    },
    children: `${getchildren(name)}`,
  };
  }
  return {
    sx: {
      bgcolor: '#',
    },
    children: '- -',
  };
}

export default function BackgroundLetterAvatars(fullname , colorbg = '' ,  width=46 , height=46, fontSize = 15 , ml='0px' , image = '') {
  return (
      <Avatar key={fullname} {...stringAvatar(fullname , width, height, fontSize, ml, image,colorbg)}  />
  );
}