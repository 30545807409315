import React, { Component } from "react";
import BackgroundLetterAvatars from "../../../../layouts/full-layout/customizer/LetterAvatar";
import {Link, ListItemAvatar, ListItemText} from '@mui/material'; 
import {fromISO} from '../../../../api/utils'
import axiosInstance from "../../../../api/axios";
import { Done, DoneAll, Visibility } from "@mui/icons-material";
export default class ChatItem extends Component {

  componentDidMount() {
    if(this.props.step !== "view"){
      if( this.props.step === "sent" && this.props.user=== "me" ) {
        //console.log(this.props)
        axiosInstance.post("/machat/acknowledge/",{'message_id':this.props.mid});
      }
    }
  }

  render() {

    return (
      <div
        style={{ animationDelay: `0.8s` }}
        className={`chat__item ${this.props.user ? this.props.user : ""}`}
      >
        <ListItemAvatar>
        <Link href={'/usermanager/edit/?user_name='+this.props.user_name}>{BackgroundLetterAvatars(this.props.fullname)}</Link>
        </ListItemAvatar>
        <div className="chat__item__content">
          <div className="chat__msg">
            <ListItemText> 
              {this.props.msg}
            </ListItemText>
            </div>
            
          <div className="chat__meta">
          </div>  
          <div className="chat__stamp">
            
            { this.props.step !== 'view' ? ((this.props.step && this.props.step === "received") ? 
            <DoneAll fontSize="4" ></DoneAll> : 
            <Done fontSize="4"></Done>) : <Visibility fontSize="4"></Visibility>} 
            {fromISO(this.props.stamp)}
          </div>
        </div>
        
      </div>
    );
  }
}