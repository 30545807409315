
import { func } from 'prop-types';
import configData from './config.json'
import axiosInstance from './axios';
import axios from 'axios';

export const  getUser = ()=>{
    if(localStorage.getItem('User')){
        let data = JSON.parse((localStorage.getItem('User')))
        return {'accessToken' : data['accessToken'] , 
        'refreshToken': data['refreshToken'] , 
        'user-name': data['user-name'] , 
        'role': data['role'] , 
        'full_name': data['full_name'],
        'email': data['email']}
    }
    return null;
};



export function handlePhoneChange(val, setPhoneNumber) {
    if (!val) setPhoneNumber(val);
    const curVal = val.replace(/[^\d]/g, '');
    const cvLength = curVal.length;
    if (cvLength < 4) setPhoneNumber(curVal);
    else if (cvLength < 7) setPhoneNumber(`(${curVal.slice(0, 3)}) ${curVal.slice(3)}`);
    else setPhoneNumber(`(${curVal.slice(0, 3)}) ${curVal.slice(3, 6)}-${curVal.slice(6, 10)}`);
  }


export const clearState = ()=>{
    localStorage.removeItem('User');
}

export const setUserAuth = ()=>{
    const currentuser = getUser()
    localStorage.setItem('AT', currentuser['accessToken'])
    localStorage.setItem('RT', currentuser['refreshToken'])
}

export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        ((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & (15 >> (c / 4))).toString(16)
    );
}

export function jwtDecode (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function emailValidation(email){
    const regex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g;
    if(!email || regex.test(email) === false){
        return false;
    }
    return true;
}

export function passwordStrength(pass_val){
        var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");

        var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
        
        var okRegex = new RegExp("(?=.{6,}).*", "g");
        if(pass_val.length < 6 )
        {
            return 'invalid';
        }
        if(okRegex.test(pass_val) === false){
            return 'weak'
        }else if(strongRegex.test(pass_val)){
            return 'strong'
        }else if(mediumRegex.test(pass_val)){
            return 'medium';
        }else{
            return 'invalid';
        }
}

export function GetEndPoint(type = ""){
    if(type == "storage"){
        return "https://ejcoremedia.blob.core.windows.net"
    }
    if(window.location.origin === 'http://localhost:3000' || window.location.origin === 'http://127.0.0.1:3000'){
        return configData['endpoint-local'];
    }
    else if (window.location.origin === 'https://ejapplication-nonprod.azurewebsites.net'){
        return configData['endpoint-stage'];
    }
    return configData['endpoint'];
}

export function GetProgramMode(){
    if(window.location.origin === 'http://localhost:3000' || window.location.origin === 'http://127.0.0.1:3000'){
        return 'local';
    }
    else if (window.location.origin === 'https://ejapplication-nonprod.azurewebsites.net'){
        return 'stage';
    }
    return 'prod';
}

export function GetFront(){
    return window.location.origin;
}

export async function GetStatus(type){
    const respons = await axiosInstance.get('maevent/getstatus/'+type)
    if(respons){
        return respons.data
    }
    return undefined
}

export function isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }

export function AcceptedImage(file){
    if(file){
        if(file.name.length > 99){
            return { "state": false, "message": "File Name is too long please rename your file before Upload." };
        }
    const support_extention = ['jpg', 'jpeg', 'png']
    const fileParts = file.name.split('.')
    const extentions = fileParts[fileParts.length-1].toLowerCase()
    const size = (file.size / 1024 / 1024).toFixed(2);
    if(support_extention.includes(extentions)){
        if(size > 2){
            return { "state": false, "message": "File size should be less than 2 MB current file have "+  (file.size / 1024 / 1024).toFixed(2) + " MB." };
        }
        return {"state":true , "img": URL.createObjectURL(file)};
    }
    return {"state":false, "message": "Extension is not acceptable."};}
    return {"state":false, "message": "Select a File."};

}

export function GetEmailContent(type){
    const emails = {
        "CreatesAccount": {
            "Condition": "Activist Applicant creates account on EJ Webapp",
            "Medium": "Email",
            "Recipient": "Activist Applicant",
            "Message":""
        },
        "ActivistExam": {
            "Condition": "",
            "Medium": "",
            "Recipient": "",
            "Message":""
        },
        "ActivistApplicationConfirmed":{
            "Condition": "",
            "Medium": "",
            "Recipient": "",
            "Message":""
        },
        "ActivistAssignToCoach":{
            "Condition": "",
            "Medium": "",
            "Recipient": "",
            "Message":""
        }
    }
}

export function GetImageUrl(username,image_name, endpoint_type = 'storage'){
    console.log(image_name);
    return GetEndPoint(endpoint_type)+'/media/'+ username+'-'+ image_name
}

export async function SendAutoEmail(triggerName, email , parameters = [])
{
    await axiosInstance.get("maevent/autotrigger/email/"+triggerName).then((email_trigger)=>{
      let body = email_trigger.data['Body']
      
    parameters.map((element)=>{
        body = body.replace(element['param'], element['value'])
      })
      // make sure it is active before we send the email
      if(email_trigger.data['active'] === true){
            let message = {
                'subject': email_trigger.data['Title'],
                'message': body,
                'title': email_trigger.data['Title'],
                'emails': [email]
            }
            axiosInstance.post('maevent/email/send/', message)
        }
    })
}

export async  function GetUserConnection(user_name,role){
    let results = undefined
    await axiosInstance.get("user/match/get/connectionv2/"+user_name+"/"+role).then((response)=>{
        if(response!== undefined){
            results =  response.data[0]
        }
    })
    return results;
}

export async function SendAutoEmailV2(triggerName, emails , parameters = [])
{
    
    await axiosInstance.get("maevent/autotrigger/email/"+triggerName).then((email_trigger)=>{
      let body = email_trigger.data['Body']
      
    parameters.map((element)=>{
        body = body.replace(element['param'], element['value'])
      })
      // make sure it is active before we send the email
      if(email_trigger.data['active'] === true){
            let message = {
                'subject': email_trigger.data['Title'],
                'message': body,
                'title': email_trigger.data['Title'],
                'emails': emails
            }
            axiosInstance.post('maevent/email/send/', message)
        }
    })
}

export function fromISO(iso) {
    const now = new Date()
    
    const date = new Date(iso);
    const daysBetween = ( now - date) / (1000 * 60 * 60 * 24);
    
    const convertedDate = new Date(date.getTime() - 14400000);
    const convertedISO = convertedDate.toISOString();
    const month = convertedISO.slice(5, 7);
    let result = "";
  
    switch (month) {
      case "01":
        result += "Jan ";
        break;
      case "02":
        result += "Feb ";
        break;
      case "03":
        result += "Mar ";
        break;
      case "04":
        result += "Apr ";
        break;
      case "05":
        result += "May ";
        break;
      case "06":
        result += "Jun ";
        break;
      case "07":
        result += "Jul ";
        break;
      case "08":
        result += "Aug ";
        break;
      case "09":
        result += "Sep ";
        break;
      case "10":
        result += "Oct ";
        break;
      case "11":
        result += "Nov ";
        break;
      case "12":
        result += "Dec ";
        break;
    }
  
    const day = convertedISO.slice(8, 10);
    if (day.slice(0, 1) === "0") {
      result += day.slice(1, 2);
    } else {
      result += day;
    }
  
    const hour = convertedISO.slice(11, 13);
    let Time = "";
    let isAM = true;
    if (hour === 0) {
      Time += " 12:";
    } else if (hour < 12) {
      Time += " " + hour + ":";
    } else {
      Time += " " + (hour - 12) + ":";
      isAM = false;
    }
  
    Time += convertedISO.slice(14, 16);
  
    if (isAM) {
      Time += " AM";
    } else {
      Time += " PM";
    }
    if (daysBetween < 1)
    {
      return "Today " + Time
    }
    if (daysBetween > 1 && daysBetween < 2){
      return "Yesterday " + Time
    }
  
  
    return result + Time;
  }


// export function rm_empty_dict_entries (obj) {
//     for (var propName in obj) {
//       if (obj[propName] === '' || obj[propName] === undefined || obj[propName] === null) {
//         delete obj[propName];
//       }
//     }
//     return obj;
//   };