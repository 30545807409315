import axios from 'axios'
import { getUser, jwtDecode, GetEndPoint } from './utils';


const baseURL = GetEndPoint();

const axiosInstance = axios.create({
    baseURL : baseURL,
    timeout: 5000,
    headers: {
        Authorization: getUser()
        ? 'JWT ' + getUser()['accessToken']
        :null,
        'content-Type': 'application/json',
        accept: 'application/json',
    }
});

axiosInstance.interceptors.response.use((response)=> {
    return response
}, async (error)=> {
    const originalRequest = error.config;
    if (typeof error.response === 'undefined') {
        // alert(
        //     'A server/network error occurred. ' +
        //         'Looks like CORS might be the problem. ' +
        //         'Sorry about this - we will get it fixed shortly.'
        // );
        console.log(error);
        return Promise.reject(error);
    }

    if (error.response.status === 401 &&
        originalRequest.url === '/user/token/refresh/') {
        window.location.href = '/auth/login/';
        return Promise.reject(error);
    }
    if (
        error.response.data.code === 'token_not_valid' &&
        error.response.status === 401
    ) {
        const currentUser = getUser()
        if (currentUser!==null){
            const refreshToken = currentUser['refreshToken'];
            const user = currentUser['user-name'];
            if (jwtDecode(refreshToken).exp > Date.now() / 1000){
                return axiosInstance
                .post('/user/token/refresh/', { refresh: refreshToken })
                 .then((response) => {
                     if(response.status === 401){
                        console.log('Refresh token not available.');
				        window.location.href = '/auth/login/';
                     }
                    if(response.status === 200){
                    const new_accessToken = response?.data?.access;
                    const new_refreshToken = response?.data?.refresh
                    localStorage.setItem('User',
                        JSON.stringify({'user-name': user ,
                        'email': currentUser['email'],
                        'role' : currentUser['role'],
                        'full_name': currentUser['full_name'],
                        'accessToken' : new_accessToken , 
                        'refreshToken': new_refreshToken})
                    )
                    axiosInstance.defaults.headers['Authorization'] =
                        'JWT ' + response.data.access;
                    originalRequest.headers['Authorization'] =
                        'JWT ' + response.data.access;
                    
                    return axiosInstance(originalRequest);
                    }
                    else
                    {
                        console.log('Server Error!');
                        window.location.href = '/auth/login/';
                    }
                })
                .catch((err) => {
                    console.log('Refresh token not available.');
                    console.log(err);
                });
            }
            else {
				console.log('Refresh token not available.');
				window.location.href = '/auth/login/';
		    }
        }else {
				console.log('Refresh token not available.');
				window.location.href = '/auth/login/';
		}
    }
    else
    {
        if(error.response.status === 401){
            if (getUser() && error.response.status === 401 && error.response.statusText === "Unauthorized")
            {
                axiosInstance.defaults.headers['Authorization'] =
                        'JWT ' + getUser()['accessToken'];
                originalRequest.headers['Authorization'] =
                        'JWT ' + getUser()['accessToken'];    
                return axiosInstance(originalRequest);
            }
            else{
                if(window.location.pathname !== '/auth/login/'){
                    window.location.href = '/auth/login/';
                }
            }
        }
        if(error.response.status === 400){
            return error.response
        }
    }
     

 });

export default axiosInstance;
