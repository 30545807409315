import React from 'react';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import PageContainer from '../../../components/container/PageContainer';
import axiosInstance from '../../../api/axios';
import { DataGrid } from '../../../components/macomponent/DataGrid';
import GroupMembers from './GroupMembers';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  Autocomplete,
  DialogTitle
} from '@mui/material';
import TextField from '@mui/material/TextField';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import ChatItem from '../../../components/apps/ChatRoom/chatContent/ChatItem';
import "../../../components/apps/ChatRoom/chatContent/chatContent.css"


import { CheckBox, CheckBoxOutlineBlank, Close } from '@mui/icons-material';

const BCrumb = [
  {
    to: '/',
    title: 'Chat',
  },
  {
    title: 'Manager',
  },
];

const ChatGroupManagement = () => {
  const [listofgroups, setListofGroups] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [update, setUpdate] = React.useState();
  const [groupid, setGroupid] = React.useState();
  let [page, setPage] = React.useState(0);
  let [rowperpage, setRowPerPage] = React.useState(15);
  let [searchgroups, setSearchGroups] = React.useState([]);
  let [autocompletevalue, setAutocompleteValue] = React.useState('');

  const [selectedguests, setSelectedGuests] = React.useState([]);
  let [searchusers, setSearchUsers] = React.useState([]);
  let [defaultguests, setDefaultGuests] = React.useState([]);
  let [convesationOpen,setConvesationOpen] = React.useState(false)
  let [conversation,setConvesation] = React.useState([]);
  
  const [text, setText] = React.useState('');

  function deletedRenderColumn(val) {
    if (val === 'true') {
      return (
        <Box>
          <CheckBox></CheckBox>
        </Box>
      );
    } else {
      return (
        <Box>
          <CheckBoxOutlineBlank></CheckBoxOutlineBlank>
        </Box>
      );
    }
  }
  const OnpageChanged = async (event, page) => {
    setPage(page);
  };
  const OnChangeRowsPerPage = async (event) => {
    setRowPerPage(event.target.value, 5);
    setPage(0);
  };
  const getGroups = (page, rowperpage) => {
    axiosInstance
      .get(
        'machat/management/getgroups/?p=' +
          (page + 1) +
          '&count=' +
          rowperpage +
          (autocompletevalue ? '&esearch=' + encodeURIComponent(autocompletevalue.group_name) : ''),
      )
      .then((response) => {
        setListofGroups(
          DataGrid(
            response?.data.results,
            [
              { columnname: 'group_name', columntext: 'Group Name' },
              { columnname: 'description', columntext: 'Description' },
              { columnname: 'date', columntext: 'Created', columntype: 'date' },
              {
                columnname: 'members',
                columntext: 'Members',
                columntype: 'custom',
                customerender: GroupMembers,
              },
              {
                columnname: '...id,group_name,description,members',
                columntext: 'Edit',
                columntype: 'action',
                icon: 'edit',
                onClick: groupEdit,
              },
              {
                columnname: '...id,members',
                columntext: 'Conversation',
                columntype: 'action',
                icon:'message-square',
                onClick: openConversation,
              },
              {
                columnname: 'deleted',
                columntext: 'Deleted',
                columntype: 'custom',
                customerender: deletedRenderColumn,
              },
            ],
            true,
            {
              count: response.data['count'],
              currentpage: page,
              rowsPerPage: rowperpage,
              handleChangePage: OnpageChanged,
              handleChangeRowsPerPage: OnChangeRowsPerPage,
            },
            900,
            -1,
            (row, index, columnValues) => {
              if (columnValues['deleted'] == 'true') {
                return '#E04F36';
              }
            },
          ),
        );
      });
  };
  React.useEffect(() => {
    getGroups(page, rowperpage);
  }, [page, rowperpage]);
  const inputChangeHandler = (e) => setTitle(e.target.value);
  const groupEdit = (val) => {
    setOpen(true);
    setUpdate(true);
    setTitle(val.group_name);
    setText(val.description);
    setDefaultGuests(val.members);
    setGroupid(val.id);
  };
  const groupAdd = () => {
    setOpen(true);
    setUpdate(false);
    setTitle('');
    setText('');
    setDefaultGuests([]);
  };
  const handleClose = () => {
    setOpen(false);
    setTitle('');
    setUpdate(null);
  };
  
  const getconversation = (sender,user_name,role,id)=>{
    if(role === 'group'){
        axiosInstance.get('machat/management/getconversationgrou/'+user_name+'/'+id).then((response)=>{
            setConvesationOpen(true);
            setConvesation(response?.data)
            //this.setState({connections: response?.data});    
        });
    }
    else{
        axiosInstance.get('machat/management/getconversation/'+user_name+'/'+sender).then((response)=>{
          setConvesationOpen(true);
          setConvesation(response?.data)
            //this.setState({connections: response?.data});    
        });
    }   };

  const openConversation= (val)=>{
    getconversation('',val.members[0]['user_name'],'group',val.id);
  };

  const conversation_handleClose = () =>{
    setConvesationOpen(false);
  }
  const deleteHandler = async (e) => {
    //e.preventDefault();
    const response = await axiosInstance.post('machat/management/deletegroup/', {
      group_id: groupid,
    });
    if (response.status === 200) {
      handleClose();
      getGroups();
    }
  };
  const updateEvent = async (e) => {
    e.preventDefault();

    var members = [];
    if (selectedguests.length === 0) {
      defaultguests.forEach((element) => {
        members.push(element.id);
      });
    } else {
      selectedguests.forEach((element) => {
        members.push(element.id);
      });
    }
    const response = await axiosInstance.post('machat/management/updategroup/', {
      group_id: groupid,
      group_name: title,
      description: text === '' ? title : text,
      members: members,
    });
    console.log(response);
    if (response.status === 200) {
      handleClose();
      getGroups();
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log({ group_name: title, description: text === '' ? title : text });
    const response = await axiosInstance.post('machat/management/creategroup/', {
      group_name: title,
      description: text === '' ? title : text,
    });
    if (response && response.status === 200) {
      var members = [];
      selectedguests.forEach((element) => {
        members.push(element.id);
      });
      setGroupid(response.data.group_id);
      const response_members = await axiosInstance.post('machat/management/addmember/', {
        group_id: response.data.group_id,
        members: members,
      });
      if (response_members && response_members.status === 200) {
        handleClose();
        getGroups();
      }
    }
  };
  return (
    <PageContainer title="Group Manager" description="Manage and upload file here">
      <Breadcrumb title="Group Manager" items={BCrumb} />
      <Button variant="contained" onClick={groupAdd}>
        Add New Group
      </Button>
      <Autocomplete
        id="free-solo-demo"
        onChange={(event, newValue) => {
          setAutocompleteValue(newValue);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            getGroups(0, rowperpage);
          }
        }}
        freeSolo
        fullWidth
        sx={{
          mb: 2,
        }}
        options={searchgroups}
        getOptionLabel={(option) => `${option.group_name}`}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search"
            onChange={async (e) => {
              await axiosInstance
                .get('machat/management/getgroups/?search=' + encodeURIComponent(e.target.value))
                .then((response) => {
                  if (response?.status === 200) {
                    setSearchGroups(response.data ? response.data['results'] : []);
                  } else {
                    setSearchGroups([]);
                  }
                });
            }}
            size="small"
            aria-label="FreeSolo"
          />
        )}
      />
      {listofgroups}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form onSubmit={update ? updateEvent : submitHandler}>
          <DialogContent>
            <Typography variant="h3" sx={{ mb: 2 }}>
              {update ? 'Update Event' : 'Add Event'}
            </Typography>
            <CustomFormLabel htmlFor="Group Title">Group Title</CustomFormLabel>
            <CustomTextField
              id="Group Title"
              placeholder="Enter Group Title"
              variant="outlined"
              fullWidth
              value={title}
              sx={{ mb: 2 }}
              onChange={inputChangeHandler}
              size="small"
            />
            <CustomFormLabel htmlFor="Guests">Guests</CustomFormLabel>
            <Autocomplete
              label="Guests"
              id="multiselect_user"
              multiple
              fullWidth
              sx={{
                mb: 2,
              }}
              onChange={(event, newValue) => {
                setSelectedGuests(newValue);
              }}
              options={searchusers}
              defaultValue={defaultguests}
              getOptionLabel={(option) =>
                `${option.email} | ${option.user_name} | ${option.first_name} ${option.last_name}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search"
                  onChange={async (e) => {
                    await axiosInstance
                      .get('user/autocomplete/list/?search=' + e.target.value)
                      .then((response) => {
                        setSearchUsers(response.data ? response.data['results'] : []);
                      });
                  }}
                  size="small"
                  aria-label="FreeSolo"
                />
              )}
            />

            <CustomFormLabel>Description</CustomFormLabel>

            <ReactQuill
              value={text}
              onChange={(value) => {
                setText(value);
              }}
              placeholder="Type here..."
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>

            {update ? (
              <Button color="error" variant="contained" onClick={() => deleteHandler(update)}>
                Delete
              </Button>
            ) : (
              ''
            )}
            <Button type="submit" disabled={!title} variant="contained">
              {update ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
      fullWidth={true}
      maxWidth={'lg'}
        open={convesationOpen}
        onClose={conversation_handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 , fontSize: 20 }}>{'Chat'}</DialogTitle>
        <Close aria-label="close"
        onClick={conversation_handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}></Close>
        <DialogContent dividers>
          {conversation.map((itm, index) => {
            return (
              <ChatItem
                animationDelay={index + 2}
                key={itm.message_id}
                user={itm.owner === 1 ? 'other' : 'me'}
                msg={JSON.parse(itm.content)['content']}
                fullname={itm.sender_fullname}
                stamp = {itm.stamp}
                step ={"view"}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={conversation_handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default ChatGroupManagement;
