import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import ChatGroupManagement from '../views/apps/chatroom/ChatGroupManagement';
import { getUser } from '../api/utils';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Logout = Loadable(lazy(()=> import('../views/authentication/Logout')))
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));
const ResetPasswordChange = Loadable(lazy(() => import('../views/authentication/ResetPasswordChange')));


/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboards/Dashboard')));

/* ****Apps***** */
const ChatRooms = Loadable(lazy(()=> import('../views/apps/chatroom/ChatRoom')))
const CharGroupManagement = Loadable(lazy(()=> import('../views/apps/chatroom/ChatGroupManagement')))
const FileManager = Loadable(lazy(()=> import('../views/apps/filemanager/FileManager')))
const SharedWithMe = Loadable(lazy(()=> import('../views/apps/filemanager/FileSharedwithme')))
const UserManager = Loadable(lazy(()=> import('../views/apps/usermanagement/UserManagement')))
const UserEdit = Loadable(lazy(()=> import('../views/apps/usermanagement/UserEdit')))
const UserBroadCastMessage = Loadable(lazy(()=> import('../views/apps/usermanagement/UserBroadCastMessage')))
const UserEmail = Loadable(lazy(()=> import('../views/apps/usermanagement/UserEmail')))
const UserAutoTrigger = Loadable(lazy(()=> import('../views/apps/usermanagement/UserAutoTrigger')))
const UserAdd = Loadable(lazy(()=> import('../views/apps/usermanagement/UserAdd')))
const EJMaching = Loadable(lazy(()=> import('../views/apps/ej-maching/EJMaching')))
const EJMachingEdit = Loadable(lazy(()=> import('../views/apps/ej-maching/EJMatchingEdit')))
const Calendar = Loadable(lazy(() => import('../views/apps/calendar/ACalendar')));
const EJExam = Loadable(lazy(() => import('../components/apps/Exam/ExamQuestions/examQuestions')));
const EJApplication = Loadable(lazy(() => import('../components/apps/UserRole/userRoleBody/userRoleBody')));


/* ****Routes***** */

const Router = [
  {
    path: '',
    element: <FullLayout />,
    children: [
      { path: '/', element: getUser() === null ? <Navigate to="auth/login" /> : <Navigate to="home" /> },
      { path: '/home', exact: true, element: <Dashboard />},
      { path: '/chatrooms', element: <ChatRooms />},
      { path: '/chatrooms/groupmanager', element: <ChatGroupManagement/>},
      { path: '/filemanager/upload', element: <FileManager />},
      { path: '/filemanager/sharedwithme', element: <SharedWithMe />},
      { path: '/usermanager', element: <UserManager/>},
      { path: '/usermanager/edit', element: <UserEdit/>},
      { path: '/usermanager/add', element: <UserAdd/>},
      { path: '/usermanager/broadcast', element: <UserBroadCastMessage/>},
      { path: '/usermanager/email', element: <UserEmail/>},
      { path: '/usermanager/autotrigger', element: <UserAutoTrigger/>},
      { path: '/ej/matching', element: <EJMaching/>},
      { path: '/ej/matching/edit', element: <EJMachingEdit/>},
      { path: '/calendar', element: <Calendar /> },
      { path: '/ej/exam', element: <EJExam /> },
      { path: '/ej/application', element: <EJApplication /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'logout', element: <Logout /> },
      { path: 'register', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'reset-password-change', element: <ResetPasswordChange /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
