import './DataGrid.css'
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Table,
    TableCell,
    Typography,
    TableHead,
    TableRow,
    TableBody,
    IconButton,
    Link,
    TablePagination,
    TableContainer,
    Box,
    CircularProgress,
    Paper
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SortIcon from '@mui/icons-material/Sort';
import axiosInstance from '../../api/axios';
import { func } from 'prop-types';
import { result } from 'lodash';
import { uuidv4 } from '../../api/utils';

// if we want to return a list of value for action column we can start with ... and then add the column by comma
// {"columnname": "...id,group_name,description,members" , "columntext": "Edit" , "columntype": "action" , "icon": "edit"  , "onClick": groupEdit}
const DICTIONARY_VALUE_INDICATOR = "...";

function TablePaginationActions(props) {
    
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1 }
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
}

function ValueCell(row,columnname){
    if (columnname.startsWith(DICTIONARY_VALUE_INDICATOR)){
      columnname = columnname.trim().substring(DICTIONARY_VALUE_INDICATOR.length,columnname.length)
      var columnnames = columnname.split(',');
      
      var val = {}
      for (let index = 0; index < columnnames.length; index++) {
        val[columnnames[index]] = row[columnnames[index].trim()];
      }
      return val;
    }
    else{
    var columnnames = columnname.split(',');
    var val= "";
    for(var i =0; i < columnnames.length; i++){
      if(columnnames[i].includes('"')){
        val+=columnnames[i].trim().substr(1,columnnames[i].length-2)
      }
      else{
        if(columnnames[i].trim().includes('.')){
          var inner_columns = columnnames[i].trim().split('.')
          var _val = row[inner_columns[0]];
          for(var j=1; j<inner_columns.length; j++){
            _val = _val[inner_columns[j]]
          }
          val+= _val;
        }
        else{
          if (Array.isArray(row[columnnames[i].trim()]))
          {
            val = row[columnnames[i].trim()];
          }
          else{
            val+= row[columnnames[i].trim()];}
        }
        }
      }
    }
    //console.log("Grid val", val === "null", val);
    return val === "null" ? '-' : val;
}

function GetColumnProperty(column,row,propertyname){
  if(propertyname === "enable"){
    if(column._enable !== undefined){
      return ValueCell(row,column._enable) === "false" ? true : false
    }
    return false
  }
}

function ColumnAction(column,row){
    return (<TableCell key={column.columntext}>
      <IconButton disabled={GetColumnProperty(column,row,"enable")} onClick={() => { const val = ValueCell(row,column.columnname); column.onClick(val); }}>
      {(column.columnname && ( column.hidden !== undefined && column.hidden === true)) &&
      <Typography id={row +'-'+ column.columntext} hidden={true}>
          {column.columnname.startsWith(DICTIONARY_VALUE_INDICATOR)?"":ValueCell(row,column.columnname)}
      </Typography>}
      <FeatherIcon
          icon={column.icon}
          width="18"
          height="18"
          sx={{
          color: (theme) => theme.palette.grey.A200,
          }}
      />
      {(column.columnname && (column.hidden === undefined || column.hidden === false))&&
          <Typography>
              {column.columnname.startsWith(DICTIONARY_VALUE_INDICATOR)?"":ValueCell(row,column.columnname)}
          </Typography>
        }
          {column.columnname_secondary &&
        <Typography color="textSecondary" variant="subtitle2">
          {ValueCell(row,column.columnname_secondary)}
        </Typography>
        }
      </IconButton>
</TableCell>)
}

function ColumnFileLink(column,row){
  return(
    <Link key={column.columntext} href={column.pattern+ValueCell(row,column.columnname)} 
                                    underline="none" target="_blank" rel="noopener">
                                    <FeatherIcon
                                            icon={column.icon}
                                            width="18"
                                            height="18"
                                            sx={{
                                            color: (theme) => theme.palette.grey.A200,
                                            }}
                                        />
                                        {column.columnname?
                                          <Typography hidden={column.hidden?true:false}>
                                              {column.columnname.startsWith(DICTIONARY_VALUE_INDICATOR)?"":ValueCell(row,column.columnname)}
                                          </Typography>
                                          :''}
                                        {column.columnname_secondary &&
                                              <Typography color="textSecondary" variant="subtitle2">
                                                {ValueCell(row,column.columnname_secondary)}
                                              </Typography>
                                        }
                                  </Link>
  );
}

function ColumnLink(column,row){
  return(
    <TableCell key={column.columntext}>
        <Link href={column.columnname.startsWith(DICTIONARY_VALUE_INDICATOR)?"":ValueCell(row,column.columnname)}>
          {column.icon? <FeatherIcon icon={column.icon} width="18" /> :
          column.columnname_secondary? <Typography>
            {ValueCell(row,column.columnname_secondary)}
          </Typography>:
          column.columntext}
        </Link>
    </TableCell>
  );
}

function ColumnText(column,row){
  return (<TableCell key={column.columntext}>
                <Typography>
                {column.columnname.startsWith(DICTIONARY_VALUE_INDICATOR)?"":ValueCell(row,column.columnname)}
                </Typography>
                {column.columnname_secondary &&
                <Typography color="textSecondary" variant="subtitle2">
                  {ValueCell(row,column.columnname_secondary)}
                </Typography>
                }
          </TableCell>);
}

function ColumnCustome(column,row){
  const val = ValueCell(row,column.columnname)
  const key = uuidv4();
    return (<TableCell key={column.columntext}>
      {column.customerender?column.customerender(val,key):<div>'Custom Render need a function to render'</div>}  
    </TableCell>);
}

function ColumnAsyncCustome(column,row){
  const val = ValueCell(row,column.columnname)
  const key = uuidv4();
  return (<TableCell key={column.columntext}>
    <Box id={key} name={key} sx={{ display: 'flex' }}>
    {column.customerender?column.customerender(val,key):<div>'Custom Render need a function to render'</div>}
    </Box>
  </TableCell>);
}

function getDateFormat(date, format, separator){
  format = format.toLowerCase();
  var year = date.toLocaleDateString("default", {year: "2-digit"})
  var month = date.toLocaleDateString("default", {month: "2-digit"})
  var day = date.toLocaleDateString("default", {day: "2-digit"})
  var formattedDate = month  + separator + day + separator + year
  return formattedDate

}

function ColumnDate(column,row){
    const val = column.columnname.startsWith(DICTIONARY_VALUE_INDICATOR)?"":ValueCell(row,column.columnname)
    const date = new Date(val)
    return (
      <TableCell key={column.columntext}>
        <Typography>
          {date.toDateString()==='Invalid Date'?'-':getDateFormat(date,"","/")}
        </Typography>
      </TableCell>
    )
}

function TableHeader(columns,hasRowId){
  return(
      <TableHead>
          <TableRow>
              {hasRowId?
              <TableCell key={"#row"}>
                  #
              </TableCell>:
              ''
              }
              {columns.map((column)=>(
                  <TableCell
                  key={column.columnname}
                  align={column.align}
                  style={{ minWidth: column.minWidth, cursor: ("ordering_function" in column)? "pointer" : "text" }}
                  onClick={("ordering_function" in column) ? ()=>{column.ordering_function(column);}: ()=>{}}
              >
                  <Typography variant="h5" fontWeight="500">
                    {("ordering_function" in column)?  <Typography> {column.columntext} <SortIcon sx={{fontSize:15}}/> </Typography> : column.columntext}
                  </Typography>
              </TableCell>
              ))}
              </TableRow>
      </TableHead>
  );
}

function _TableRow(columns,row,index, hasRowId,rowIndicatorConstrain){
  let backgroundColor= "#fff";
  if(rowIndicatorConstrain!==null){
    let _columnsValues = {}
    columns.forEach(element => {
      _columnsValues[element.columnname] = ValueCell(row,element.columnname);
    });
    backgroundColor = rowIndicatorConstrain(row,index,_columnsValues)
  }
  return (
    <TableRow hover key={index} sx={{backgroundColor:backgroundColor}}>
    {hasRowId?
    <TableCell>
        {index+1}
    </TableCell>:
    ''
    }
    { columns.map( (column)=>{
            if(column.columntype === 'filelink'){
              return ColumnFileLink(column,row)
            }
            else if (column.columntype === 'action') {
              return ColumnAction(column,row)
            } 
            else if(column.columntype === 'custom'){
              return ColumnCustome(column,row);
            }
            else if(column.columntype === 'asyncustom'){
              var value = ColumnAsyncCustome(column,row)
              return value;
            }
            else if(column.columntype === 'date'){
              return ColumnDate(column,row);
            }
            else if(column.columntype === 'link'){
              return ColumnLink(column,row);
            }
            else {
              return ColumnText(column,row);   
            } 
        })
    }
    </TableRow>
  );
}

function _TableBody(data,columns,hasRowId,rowIndicatorConstrain){
  return (
      <TableBody>
                { 
                  data.map((row , index)=>{
                    return _TableRow(columns,row,index,hasRowId,rowIndicatorConstrain);
                  })
                }
      </TableBody>
  )

}

function _TablePagination(pagination,data_length){
  return (
    <div>
      {pagination!==null &&  (             
      <TablePagination
             rowsPerPageOptions={[5, 10, 25]}
             component="div"
             colSpan={6}
             count={pagination.count}
             rowsPerPage={pagination.rowsPerPage}
             page={ pagination.currentpage}
             SelectProps={{
             inputprops: {
                 'aria-label': 'rows per page',
             },
             native: true,
             }}
             onPageChange={pagination.handleChangePage}
             onRowsPerPageChange={pagination.handleChangeRowsPerPage}
             ActionsComponent={TablePaginationActions}
         />        
      )}
    </div>
  )
}


export function DataGrid(data, columns , 
  hasRowId = true  , 
  pagination = null , 
  maxHeight = 440,
  maxWidth = -1,
  rowIndicatorConstrain  = null )
{
    return (
        <Paper sx={{ width: maxWidth === -1 ? '100%' : maxWidth, overflow: 'hidden' }}>
            <TableContainer
                sx={{
                  maxHeight: maxHeight,
                }}
              >
            <Table stickyHeader aria-label="sticky table">
                {TableHeader(columns,hasRowId)}
                {_TableBody(data,columns,hasRowId,rowIndicatorConstrain)}
            </Table>
            </TableContainer>
            {_TablePagination(pagination,data.length)}
        </Paper>
    );    
}



export async function DataGrid2(url ,  columns , parameters = {"page": 1,
"rowperpage" : 10 ,
"ordering" : ["-created_at"],
"autocompletevalue": ''}, 
hasRowId = true  , pagination = null , maxHeight = 440)
{
  await axiosInstance.get(url + "/?p=" +parameters["page"]+1+"&count="+parameters["rowperpage"]+
  (parameters["autocompletevalue"]?"&search="+parameters["autocompletevalue"]:'')+"&ordering="+parameters["ordering"]).then((response)=>{
    if(response?.status === 200){
      let data = response.data['results']
      return DataGrid(data,columns,hasRowId,pagination, maxHeight);
    }
  });
}



